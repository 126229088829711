import React from 'react'
import { graphql } from 'gatsby'
import SectionNotFound from '../components/SectionNotFound/SectionNotFound'
import Seo from '../components/seo';

const PageNotFound = ({
    pageContext,
    data: {
        contentfulStlPageCustom: {
            slug
        }
    }
}) => {
    return (
        <div>
            <Seo
                title="404"
                description="Page Not Found"
                slug={slug}
            />
            <SectionNotFound />
        </div>
    )
}

export const PageNotFoundQuery = graphql`
    query PageNotFoundQuery($id: String!){
        contentfulStlPageCustom(id: {eq: $id}){
            slug
        }
    }
`

export default PageNotFound