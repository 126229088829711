import React from 'react'
import styled from 'styled-components'

const SectionNotFound = () => {
    return (
        <Wrapper>
            <h1 className='heading heading-1'>Page Not Found</h1>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    min-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
`

export default SectionNotFound